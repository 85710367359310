import React from "react"

import Layout from "../components/layout"

import "../styles/ephesus-suite.css"
import { navigate } from "gatsby"

const EphesusSuite = props => {
  return (
    <Layout location={props.location}>
      <div className="ephesus-suite-page">
        <div className="ephesus-suite-page__top">
          <div className="ephesus-suite-page__top__left">
            <h1 className="ephesus-suite-page__top__left__title">
              Security and Intelligence
            </h1>
            <p className="ephesus-suite-page__top__left__detail">
              Dataunitor have a range of solutions targeting protection of
              people, customers, employees, infrastructure, buildings,
              facilities, campuses, plants and assets against threats. It
              addresses safeguarding needs within public security, law
              enforcement, corporate security, large event security and more.
            </p>
          </div>
          <img
            className="ephesus-suite-page__top__image"
            src="/images/nginr.png"
            alt="ephesus"
          />
        </div>
        <div className="ephesus-suite-page__line"></div>
        <div className="ephesus-suite-page__bottom">
          <h1 className="ephesus-suite-page__bottom__title">nginr<sup><small>®</small></sup> Solutions</h1>
          <p className="ephesus-suite-page__bottom__detail">
            The solution comes with a broad range of ready trained models and
            components to recognize objects, incidents and threats such as
            automated number plate recognition (ANPR), crowding, weapons and
            much more. The graphical dashboard presents threat warnings and
            alerts. Pay your attention when and where it is most needed. Drill
            down to review and make informed decisions and respond quickly.
            Reduce manual monitoring and automate frequent tasks and responses.
            Typical customers are security operation centers and security
            service providers. We dare to say that no operation is to large –
            our architecture scales to thousands of heterogeneous inputs such as
            videostreams, access controls, room occupancy sensors and more.{" "}
            <br />
            <br />
            All nginr<sup><small>®</small></sup> Solutions provide compliance with privacy laws and
            regulations such as GDPR and we aim to enable our clients to provide
            big data analytics through privacy by design solutions. Moreover,
            the nginr<sup><small>®</small></sup> Solutions can provide not only the government and
            corporate sectors with the capability to fuse actionable and
            validated knowledge from big data but also the members of societies,
            by reducing the individuals` susceptibility to the malicious
            behaviors and manipulation through the Internet. nginr<sup><small>®</small></sup> is built on
            top of the nginr<sup><small>®</small></sup> platform enabling continuous analytics for
            decision support and automation. The Actionable Intelligence is
            generated by accessing and processing multiple heterogenous sources
            of data such as building sensors, cameras and access control
            systems. This omnipresence approach enables early warning detection
            and safeguarding against complex security threats.
          </p>
          <div className="ephesus-suite-page__bottom__product">
            <img
              className="ephesus-suite-page__bottom__product__image"
              src="/images/ephesus-mobil.png"
              alt="ephesus"
            />
            <div className="ephesus-suite-page__bottom__product__right">
              <h1 className="ephesus-suite-page__bottom__product__right__title">
                Security Intelligence
              </h1>
              <p className="ephesus-suite-page__bottom__product__right__detail">
                Security is a 24/7 effort and the traditional way of highly
                manual monitoring is labor intensive and costly. Manual
                monitoring to identify threats and incidents is vulnerable and
                error-prone making it difficult to monitor and assure quality of
                service and response time. <br />
                <br />
                The security module enables transformation of a security
                operations to a condition-based surveillance and monitoring
                system. Organize data from all your security systems, sensor and
                other systems into one unified structure. <br />
                <br />
                The solution is based on the nginr
                <sup>
                  <small>®</small>
                </sup>{" "}
                platform enabled with artificial intelligence (AI) and deep
                learning technologies. This enables the solution with real-time
                capabilities of video surveillance to alert about incidents and
                provide early warning of anomalies and possible security
                threats. <br />
              </p>
              <br />
              <h1 className="ephesus-suite-page__bottom__product__right__title">
                Business Intelligence
              </h1>
              <p className="ephesus-suite-page__bottom__product__right__detail">
                We provide the corporate sector with smart command and control,
                management, business analytics, prediction and forecasting,
                adaptive maintenance and logistics, and business process
                optimization capabilities. <br />
              </p>
              <br />
              <h1 className="ephesus-suite-page__bottom__product__right__title">
                Policy Intelligence
              </h1>
              <p className="ephesus-suite-page__bottom__product__right__detail">
                For governmental organization we offer customised solutions
                combating illegal immigration, human smuggling, human
                trafficking, illegal financial flows and organized crime. <br />
              </p>
              <br />
              <h1 className="ephesus-suite-page__bottom__product__right__title">
                Event Management
              </h1>
              <p className="ephesus-suite-page__bottom__product__right__detail">
                For private and public clients running complex international
                events, we offer solutions with smart administration,
                management, security and safety services. Moreover, various live
                digital event experience and gamification features are provided
                upon specific client needs. <br />
              </p>
              <p className="ephesus-suite-page__bottom__product__right__detail">
                <b>
                  Get <u onClick={() => navigate("/contact")}>{"in touch"}</u>{" "}
                  to discuss your needs and any special requirements.
                </b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default EphesusSuite
